.App {
    font-family: 'Jost', Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    text-align: center;
    color: #202124;
    margin: 0 10px 0 10px;
}

h2 {
    margin-top: 0;
}

h1 {
    margin: 10px 0 10px 0;
}

h1, h2 {
    font-weight: 600;
}

.logo-image {
    width: 100%;
}

.logo-wrap {
    width: 100%;
}

@media screen and (min-width: 425px) {
    .App {
        margin: 0 30px 0 30px;
    }

    .logo-image {
        width: 50%;
        min-width: 350px;
    }
}

@media screen and (min-width: 768px) {

    .logo-image {
        width: 60%;
        min-width: 350px;
    }
}

@media screen and (min-width: 1440px) {

    h1 {
        font-size: 52px;
    }

    h2 {
        font-size: 35px;
    }
}